@import "index", "font", "basic", "layout";

body > header, body > article, body > footer {
  padding: 1.5em; }

@media (min-width: 32em) {
  body > header, body > article, body > footer {
    padding: 1.75em calc(38% - 12em); } }



body > header {
  background: lighten($dark, 1%);
  color: #fff;
  border-bottom: 1px solid rgba($light, 0.05); }




